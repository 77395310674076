import React, { useState, useEffect } from "react"

import Layout from "../components/layout"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"

import SEO from "../components/seo"
import "../css/pages/alwaysapril.scss"

const AlwaysApril = () => {
  let language = "en"

  const CLIENT_NAME =
    "Fresno Oral Maxillofacial Surgery & Dental Implant Center"
  const CALENDLY_LINK =
    "https://calendly.com/secureform/fresno-oral-surgery-always-april"

  // Make this an empty array if there are not multiple calendlies
  const MULTIPLE_CALENDLIES = []

  const YOUTUBE = "IBb3zOrL8co"

  // CSS changes are not sticking via useEffect,
  // so we setClient to true before changing css
  const [isClient, setClient] = useState(false)

  useEffect(() => {
    setClient(true)
  }, [])

  useEffect(() => {
    function makeNavWhite() {
      let nav = document.getElementById("mainNav")
      if (!nav) throw new Error("No nav found.")
      nav.style.backgroundColor = "white"
    }

    makeNavWhite()
  }, [isClient])

  return (
    <SharedStateProvider>
      <Layout pageTitle="procedure-page" language={language}>
        <SEO
          lang={language}
          title="Always April"
          description={
            "During Oral Cancer Awareness Month, " +
            CLIENT_NAME +
            " is providing free screenings to members of our community."
          }
        />
        {/* <Button 
          buttonText={language === "es" ? "ATRÁS" : "BACK"}
          goBack
          /> */}

        <div className="joshua-tree-content">
          <div className="alwaysapril">
            <div className="subpage april-img">
              <img src="https://res.cloudinary.com/nuvolum/image/upload/f_auto,q_auto/v1/Programs/always-april-logo.svg" />
            </div>
            <div className="april-grid">
              <div className="april-text">
                <p>
                  Did you know that April is Oral Cancer Awareness Month? Fresno
                  Oral Maxillofacial Surgery & Dental Implant Center has
                  partnered with Always April, a national partnership of oral
                  surgery practices across the country, as we strive to bring
                  oral cancer awareness into a year-round discussion. We know
                  the importance of early detection, and we actively look for
                  signs of oral cancer during patient visits.{" "}
                </p>
                <h2>The facts:</h2>
                <ul className="color-bullet">
                  <li>Oral cancer is rising in low-risk groups.</li>
                  <li>
                    Someone is diagnosed with oral cancer every 10 minutes in
                    the United States.
                  </li>
                  <li>
                    One person dies from oral cancer every 49 minutes in the
                    United States.
                  </li>
                  <li>
                    Historically, the death rate associated with this cancer is
                    particularly high—not because it is hard to discover or
                    diagnose, but due to the cancer being routinely discovered
                    late in its development.
                  </li>
                  <li>
                    For decades, oral cancer has affected 6 men for every 1
                    woman. Now the ratio is 2 to 1.
                  </li>
                </ul>
              </div>
              <div className="april-info">
                <h2>
                  Thank you for your interest in Always April. Our free oral
                  cancer screening event is over.
                </h2>
                <span>
                  It is important to be aware of changes that happen in your
                  mouth and continue routine dental visits and cleanings. If you
                  notice any change in your oral health, please contact your
                  oral health provider for an examination.
                </span>
                {/* <h2>
                  Screenings take just a few minutes. Schedule your FREE
                  appointment today.
                </h2>
                <span>DATE: Saturday, April 17, 2021</span>
                <span>TIME: 10:00 AM to 12 NOON</span>
                <br /> */}
                {/* <span>LOCATIONS</span> */}
                {/* <span>
                  <strong>
                    Fresno Oral Maxillofacial Surgery & Dental Implant Center
                  </strong>
                </span>
                <span>1903 E Fir Ave, Ste 101</span>
                <span>Fresno, CA 93720</span>
                <br />

                <br /> */}
                {/* <span>Screening Partners</span>
      <ul>
      <li>Partner</li>
      <li>Partner</li>
      </ul> */}

                {/* {MULTIPLE_CALENDLIES && MULTIPLE_CALENDLIES.length > 0 ? (
                  <div className="schedule-group">
                    {MULTIPLE_CALENDLIES.map((calendly) => (
                      <a
                        key={calendly.link}
                        className="schedule"
                        href={calendly.link}
                        target="_blank"
                        rel="noreferrer"
                      >
                        SCHEDULE APPOINTMENT -<br />
                        {calendly.cityCaps}
                      </a>
                    ))}
                  </div>
                ) : CALENDLY_LINK ? (
                  <a
                    className="schedule"
                    href={CALENDLY_LINK}
                    target="_blank"
                    rel="noreferrer"
                  >
                    SCHEDULE APPOINTMENT
                  </a>
                ) : null} */}
              </div>
            </div>
          </div>

          {YOUTUBE && (
            <>
              <div className="april-yt-wrapper">
                <iframe
                  src={`https://www.youtube.com/embed/${YOUTUBE}`}
                  frameBorder="0"
                  allow="autoplay; encrypted-media"
                  allowFullScreen
                  title="video"
                  className="april-yt"
                />
              </div>
            </>
          )}

          {/* {CALENDLY_LINK && (
            <span className="disclaimer">
              *Advanced appointments requested. Local health guidelines for
              COVID-19 will be followed and masks are required as we look to
              provide a safe and healthy environment for you, our patients,
              staff, and&nbsp;doctors.
            </span>
          )} */}
          <span className="disclaimer"> </span>
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export default AlwaysApril
